<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("__storebill_title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined @click="loadBillings(statusSelect)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card-title>
      <v-row>
        <v-col :cols="isMobile ? 12 : 4">
          <v-select
            outlined
            dense
            :label="$t('__storebill_status')"
            :items="billingStatus"
            item-text="ch"
            item-value="en"
            v-model="statusSelect"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="data">
        <template v-slot:[`item.id`]="{ item }">
          <v-btn text small color="primary" @click="toBilling(item.id)">
            {{ item.id }}
          </v-btn>
        </template>
        <template v-slot:[`item.period`]="{ item }">
          {{
            item.period !== "0年" && item.period !== "0year"
              ? item.period
              : item.periodStartDate + " ~ " + item.periodEndDate
          }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | currency }}
        </template>
        <template v-slot:[`item.deadline`]="{ item }">
          {{ item.deadline }}
          <v-chip
            :color="item.expired"
            small
            v-if="item.expired && item.status === 'unpaid'"
          >
            {{ $t("__storebill_over_deadline") }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status === 'paid'" color="success">
            {{ $t(`__storebill_status_${item.status}`) }}
          </v-chip>
          <v-chip v-else-if="item.status === 'unpaid'" color="error">
            {{ $t(`__storebill_status_${item.status}`) }}
          </v-chip>
          <v-chip v-else color="grey" dark>
            {{ $t(`__storebill_status_${item.status}`) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  name: "Billing",
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      userID: "user/uid",
      billings: "billing/datas"
    })
  },
  data() {
    return {
      billingStatus: [
        {
          en: "unpaid",
          ch: this.$t("__storebill_status_unpaid")
        },
        {
          en: "paid",
          ch: this.$t("__storebill_status_paid")
        },
        {
          en: "cancelled",
          ch: this.$t("__storebill_status_cancelled")
        }
      ],
      headers: [
        {
          text: this.$t("__storebill_ID"),
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: this.$t("__storebill_period"),
          align: "start",
          value: "period"
        },
        {
          text: this.$t("__storebill_amount"),
          value: "amount"
        },
        {
          text: this.$t("__storebill_deadline"),
          value: "deadline"
        },
        {
          text: this.$t("__storebill_status"),
          value: "status"
        }
      ],
      data: [],
      statusSelect: "unpaid"
    };
  },
  methods: {
    loadBillings(status) {
      const userID = this.userID;
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("billing/getBillings", { userID, status })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    formatBillings() {
      this.data = [];
      for (const billing of this.billings) {
        const data = billing.data;
        this.data.push({
          id: billing.id,
          period: data.month
            ? `${data.year}/${data.month}`
            : data.year + this.$t("__storebill_period_year"),
          periodStartDate: data.year
            ? null
            : format(new Date(data["start_time"]), "yyyy/MM/dd"),
          periodEndDate: data.year
            ? null
            : format(new Date(data["end_time"]), "yyyy/MM/dd"),
          amount: data.amount,
          deadline: format(new Date(data.deadline), "yyyy/MM/dd"),
          status: data.status,
          expired: this.checkExpired(data.deadline)
        });
      }
    },
    toBilling(id) {
      this.$router.push({ name: "Billing", params: { id } });
    },
    checkExpired(endTime) {
      let today = format(new Date(), "T");
      let endTimeTimeStamp = format(new Date(endTime), "T");
      if (today >= endTimeTimeStamp) return "error";
      else return null;
    }
  },
  watch: {
    statusSelect: {
      immediate: true,
      handler(val) {
        this.loadBillings(val);
      }
    },
    billings: {
      handler() {
        this.formatBillings();
      }
    }
  }
};
</script>
