var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("__storebill_title")))]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.loadBillings(_vm.statusSelect)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('__storebill_status'),"items":_vm.billingStatus,"item-text":"ch","item-value":"en"},model:{value:(_vm.statusSelect),callback:function ($$v) {_vm.statusSelect=$$v},expression:"statusSelect"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.toBilling(item.id)}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period !== "0年" && item.period !== "0year" ? item.period : item.periodStartDate + " ~ " + item.periodEndDate)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deadline)+" "),(item.expired && item.status === 'unpaid')?_c('v-chip',{attrs:{"color":item.expired,"small":""}},[_vm._v(" "+_vm._s(_vm.$t("__storebill_over_deadline"))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'paid')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(("__storebill_status_" + (item.status))))+" ")]):(item.status === 'unpaid')?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.$t(("__storebill_status_" + (item.status))))+" ")]):_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(" "+_vm._s(_vm.$t(("__storebill_status_" + (item.status))))+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }